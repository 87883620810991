// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-me-jsx": () => import("./../src/pages/about-me.jsx" /* webpackChunkName: "component---src-pages-about-me-jsx" */),
  "component---src-pages-clash-royale-jsx": () => import("./../src/pages/clash-royale.jsx" /* webpackChunkName: "component---src-pages-clash-royale-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-other-pages-jsx": () => import("./../src/pages/other-pages.jsx" /* webpackChunkName: "component---src-pages-other-pages-jsx" */),
  "component---src-pages-podcasts-jsx": () => import("./../src/pages/podcasts.jsx" /* webpackChunkName: "component---src-pages-podcasts-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */),
  "component---src-pages-visualizations-jsx": () => import("./../src/pages/visualizations.jsx" /* webpackChunkName: "component---src-pages-visualizations-jsx" */),
  "component---src-pages-work-experience-jsx": () => import("./../src/pages/work-experience.jsx" /* webpackChunkName: "component---src-pages-work-experience-jsx" */)
}

